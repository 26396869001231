import { OptionType } from "../../../modules/types/Types";
import { ExplorerStorageType } from "../../common/helpers/ExplorerStorage";
import UserSelect, { DateRangeType, ExplorerType } from "../../common/helpers/UserSelect";
import { FuncGetPerformanceProps } from "../../common/services/models/PerformanceTypes";
import { EntitySimpleType } from "../../common/services/models/PublicTypes";
import AdlyAiCode from "../../modules/code/AdlyAiCode";
import Code from "../../modules/code/Code";
import GfaCode from "../../modules/code/GfaCode";
import GoogleCode from "../../modules/code/GoogleCode";
import KakaoCode from "../../modules/code/KakaoCode";
import KakaoMomentCode from "../../modules/code/KakaoMomentCode";
import MetaCode from "../../modules/code/MetaCode";
import NaverCode from "../../modules/code/NaverCode";
import NospCode from "../../modules/code/NospCode";
import { getGfaPerformance } from "../gfa/explorer/StatInfoData";
import { getGooglePerformance } from "../google/explorer/StatInfoData";
import { getKakaoSaPerformance } from "../kakao/explorer/StatInfoData";
import { getKakaoMomentPerformance } from "../kakaomoment/explorer/StatInfoData";
import { getMetaPerformance } from "../meta/explorer/StatInfoData";
import { HeaderColumn } from "../modules/table/TableType";
import { getNaverSaPerformance } from "../naver/explorer/StatInfoData";
import { getNospPerformance } from "../nosp/explorer/StatInfoData";

export const getExplorerData = async (props?:FuncGetPerformanceProps)=>{
    const explorer:ExplorerType|undefined = UserSelect.explorer.get();
    const dateRange:DateRangeType|undefined = UserSelect.dateRange.get();
    const deviceTypes:string[]|undefined = UserSelect.deviceType.get();
    console.log(explorer);
    const config:FuncGetPerformanceProps = {
        ...props,
        mediaType: props?.mediaType || explorer?.media,
        level: props?.statLevel || props?.level || explorer?.level,
        search: props?.search || explorer?.search,
        deviceTypes: props?.deviceTypes || deviceTypes,
        startDate: props?.startDate || dateRange?.startDate,
        endDate: props?.endDate || dateRange?.endDate,
    };
    let data:any[] = [];

    switch(config.mediaType){
        case Code.base.mediaType.NAVER.value        : data = await getNaverSaPerformance(config)    as any[]; break;
        case Code.base.mediaType.KAKAO.value        : data = await getKakaoSaPerformance(config)    as any[]; break;
        case Code.base.mediaType.NAVER_GFA.value    : data = await getGfaPerformance(config)        as any[]; break;
        case Code.base.mediaType.NAVER_NOSP.value   : data = await getNospPerformance(config)       as any[]; break;
        case Code.base.mediaType.KAKAO_MOMENT.value : data = await getKakaoMomentPerformance(config) as any[]; break;
        case Code.base.mediaType.GOOGLE.value       : data = await getGooglePerformance(config)     as any[]; break;
        case Code.base.mediaType.META.value         : data = await getMetaPerformance(config)       as any[]; break;
    }

    const range = {start: new Date(config.startDate ||''), end:new Date(config.endDate ||'')};
    const days = range.end.dateDiff(range.start);
    const defData:any = {};
    props?.columns?.forEach((v)=>{ defData[v.accessor] = 0;});
    const noneDate:boolean = props?.columns?.find((v)=>v.accessor === 'date') ? false : true;
    for(let i=0; i<=days; i++){
        const key = range.start.addDays(i).format('yyyyMMdd');
        const date = range.start.addDays(i).format('yyyy-MM-dd');
        const item = data.find((v)=>v.key === key || v.date === date || v.date === key || v.key === date);
        if( noneDate && item ){ item.key=key; item.date = date}
        if( !item){ data.push({...defData, key, date})}
        else{ item!.date = date; }
    }
    return data.sort((a,b)=>new Date(a.date).getTime()-new Date(b.date).getTime()); //날짜 정렬
}

export const applyColumnFormatter = (data:any[], columns?:HeaderColumn[], indicators?:OptionType[]):any[]=>{
    // header columns에 설정한 formatter, filter를 적용하여 데이터를 가공한다.
    return data.map((row)=>{
        const map =new Map(Object.entries(row));
        indicators?.forEach((v,index)=>{
            const header = columns?.find((h)=>h.key || h.accessor === v.value);
            // 헤더나 포맷 설정이 없는 경우, 무시
            if(!header || !header.formatter){ return;}

            // 값읽는 것은 accessor로 읽고, formatter로 가공한다.
            let value = map.get(header.accessor);
            // 값이 없는 경우, 무시
            if(value === undefined){ return;}
            value = header.formatter(value, row, index);    //CSV사용하기에 콤마제거
            // 가공된 값을 다시 map에 넣는다.
            map.set(header.accessor, parseFloat(value?.toString().removeComma() || '') || 0);
        },{});
        // map을 다시 object로 변환한다.
        return Object.fromEntries(map);
    });
}


/** 선택한 컬럼만 전송용 데이터 생성하기 */
export const getEntityData = (data:ExplorerStorageType, explorerData?: any[], indicators?:OptionType[],  columns?:HeaderColumn[]):any[]=>{
    const targetList:EntitySimpleType[] = (
        data.type == AdlyAiCode.data.type.entryType.entity.value 
        ? data.entityTargetList 
        : data.pageTargetList
    ) || [];
    const keys:string[] = indicators?.map((v)=>v.value) || [];
    const resource:any[] = explorerData || (data.type===AdlyAiCode.data.type.entryType.entity.value ? [data.entityData] : data.pageData || []);
    return resource.map((v)=>{
        const map = new Map(Object.entries(v));
        const obj:any = {};
        obj['id'] = v.id || v.key || v.criterionId;
        obj['name'] = targetList.find((v2)=>v2.id===v.key)?.name || v.id;
        obj['date'] = v.date;
        keys.forEach((v2)=>{
            obj[v2] = map.get(v2);
        });
        return obj;
    });
}

export interface EntityDataType{ weekly?:any[], daily?:any[], monthly?:any[] }
/** EntityData를 JSON로 생성 */
export const getEntityDataJson = (data:ExplorerStorageType, explorerData?: any[], indicators?:OptionType[], columns?:HeaderColumn[], source?:string|string[]):EntityDataType|any=>{
    const keys:string[] = indicators?.map((v)=>v.value) || [];
    const temp:any[] = explorerData || (data.type===AdlyAiCode.data.type.entryType.entity.value ? [data.entityData] : data.pageData || []);
    const resource:any[] = applyColumnFormatter(temp, columns, indicators);

    const res:any[] = [];

    // explorerData가 있으면, 그 데이터를 사용한다.
    if(explorerData){ return getEntityExplorData(data, explorerData, indicators, columns, source?.toString()); }

    // 합계구하기
    const sum:any = {id:'합계',};
    keys.forEach((v)=>{
        sum[v] = 0;
    });
  
    res.push({
        id:'아이디',
        ...(Object.fromEntries(indicators?.map(v=>([v.value, v.label])) || []))
    });

    resource.forEach((v)=>{
        const map = new Map(Object.entries(v));
        const key = v.id || v.key || map.get(data.chekcedId || "");
        const values:[string,any][] = [
            ["id", key],
        ];
        keys.forEach((v2)=>{
            values.push([v2, map.get(v2) || 0]);
            sum[v2] += map.get(v2)?.toString().toNumber() || 0;
        });
        res.push(Object.fromEntries(values));
    });
    
    const avg:any = {id:'평균',};
    keys.forEach((v)=>{
        avg[v] = sum[v] / resource.length;
    });
    res.push(avg);
    res.push(sum);

    return res;
}

const getEntityExplorData = (data:ExplorerStorageType, explorerData?: any[], indicators?:OptionType[],  columns?:HeaderColumn[], source?:string):EntityDataType=>{
    const keys:string[] = indicators?.map((v)=>v.value) || [];
    const temp:any[] = explorerData || (data.type===AdlyAiCode.data.type.entryType.entity.value ? [data.entityData] : data.pageData || []);
    const resource:any[] = applyColumnFormatter(temp, columns, indicators);

    const res:any[] = [];

    // 합계구하기
    const sum:any = {id:'합계', date:'합계',};
    keys.forEach((v)=>{
        sum[v] = 0;
    });
  
    res.push({
        id:'아이디',
        date: '날짜',
        ...(Object.fromEntries(indicators?.map(v=>([v.value, v.label])) || []))
    });

    resource.forEach((v)=>{
        const map = new Map(Object.entries(v));
        const key = v.id || v.key || map.get(data.chekcedId || "");
        const values:[string,any][] = [
            ["id", key],
            ["date", v.date]
        ];
        keys.forEach((v2)=>{
            values.push([v2, map.get(v2)]);
            sum[v2] += map.get(v2)?.toString().toNumber() || 0;
        });
        res.push(Object.fromEntries(values));
    });

    const avg:any = {id:'평균', date:'평균',};
    keys.forEach((v)=>{
        avg[v] = sum[v] / resource.length;
    });
    res.push(avg);
    res.push({...sum});

    // 주차별 합계
    const weekly:any[] = [];
    weekly.push({
        week: '주차별',
        ...(Object.fromEntries(indicators?.map(v=>([v.value, v.label])) || []))
    });
    weekly.push( ...getWeeklySum(resource, keys));
    const avgWeekly:any = {week:'평균',};
    keys.forEach((v)=>{
        avgWeekly[v] = sum[v] / weekly.length -1;
    });
    weekly.push(avgWeekly);
    delete sum.date;
    delete sum.id;
    weekly.push({week:"합계", ...sum});
    

    // 월별 합계
    const monthly:any[] = [];
    monthly.push({
        month: '월별',
        ...(Object.fromEntries(indicators?.map(v=>([v.value, v.label])) || []))
    });
    monthly.push( ...getMonthlySum(resource, keys));
    const avgMonthly:any = {month:'평균',};
    keys.forEach((v)=>{
        avgMonthly[v] = sum[v] / monthly.length -1;
    });
    monthly.push(avgMonthly);
    monthly.push({month:"합계", ...sum});

    return {
        daily: res,
        weekly: source==='weekly' ? weekly : undefined,
        monthly: source==='monthly' ? monthly : undefined,
    };
}



// 📌 특정 날짜의 ISO 주차를 계산하는 함수
const getISOWeek = (date: Date) => {
    const tempDate = new Date(date);
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 4 - (tempDate.getDay() || 7));
    const yearStart = new Date(tempDate.getFullYear(), 0, 1);
    return {year:yearStart.getFullYear(), week:Math.ceil((((tempDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7)};
};

// 📌 주차별 합계 계산 함수
const getWeeklySum = (resource: any[], keys: string[]) => {
    const weekly: Record<string, any> = {};

    resource.forEach((entry) => {
        const info = getISOWeek(new Date(entry.date));

        if (!weekly[info.week]) {
            weekly[info.week] = { week: `${info.year}-W${info.week.zeroFill(2)}` };
            keys.forEach((key) => (weekly[info.week][key] = 0));
        }

        keys.forEach((key) => {
            const value = Number(entry[key]?.toString().toNumber()) || 0;
            weekly[info.week][key] += value;
        });
    });

    return Object.values(weekly);
};

// 📌 특정 날짜의 연-월 문자열 반환 함수 (YYYY-MM)
const getYearMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월을 2자리로 변환
    return `${year}-${month}`;
};

// 📌 월별 합계 계산 함수
const getMonthlySum = (resource: any[], keys: string[]) => {
    const monthly: Record<string, any> = {};

    resource.forEach((entry) => {
        const monthKey = getYearMonth(new Date(entry.date)); // 연-월 (YYYY-MM)

        if (!monthly[monthKey]) {
            monthly[monthKey] = { month: monthKey };
            keys.forEach((key) => (monthly[monthKey][key] = 0));
        }

        keys.forEach((key) => {
            const value = Number(entry[key]?.toString().toNumber()) || 0;
            monthly[monthKey][key] += value;
        });
    });

    return Object.values(monthly); // 객체를 배열로 변환하여 반환
};


/** EntityData를 csv로 생성 */
export const getEntityDataCsv = (data:ExplorerStorageType, explorerData?: any[], indicators?:OptionType[],  columns?:HeaderColumn[]):string=>{
    const targetList:EntitySimpleType[] = (
        data.type == AdlyAiCode.data.type.entryType.entity.value 
        ? data.entityTargetList 
        : data.pageTargetList
    ) || [];
    const keys:string[] = indicators?.map((v)=>v.value) || [];
    const temp:any[] = explorerData || (data.type===AdlyAiCode.data.type.entryType.entity.value ? [data.entityData] : data.pageData || []);
    const resource:any[] = applyColumnFormatter(temp, columns, indicators);

    const res:string[] = [];
    res.push(['아이디', '날짜', ...(indicators?.map(v=>v.label) || [])].join('\t'));
    resource.forEach((v)=>{
        const map = new Map(Object.entries(v));
        const values:any[] = [
            v.id||v.key,
            // targetList.find((v2)=>v2.id===v.key)?.name || v.id,
            v.date
        ];
        keys.forEach((v2)=>{
            values.push(map.get(v2));
        });
        res.push(values.join('\t'));
    });
    return res.join('\n');
}


/** 선택한 entity 정보 표시용 정보 */
export const getEntityCodeInfo = (mediaType?:string, level?:string):OptionType|undefined=>{
    switch(mediaType){
        case Code.base.mediaType.NAVER.code         : return Code.get([
                                                            NaverCode.data.options.ExplorerLevel,
                                                            NaverCode.data.assets.criterion,
                                                 ]      ,                                                           level || ''); 
        case Code.base.mediaType.KAKAO.code         : return Code.get(KakaoCode.data.options.ExplorerLevel,         level || ''); 
        case Code.base.mediaType.NAVER_GFA.code     : return Code.get(GfaCode.data.options.ExplorerLevel,           level || ''); 
        case Code.base.mediaType.NAVER_NOSP.code    : return Code.get([
                                                            NospCode.data.options.ExplorerLevel,
                                                            NospCode.data.options.campaignDimensionType,
                                                            NospCode.data.options.keywordDimensionType
                                                        ],                                                          level || ''); 
        case Code.base.mediaType.KAKAO_MOMENT.code  : return Code.get([
                                                            KakaoMomentCode.data.options.ExplorerLevel,
                                                            KakaoMomentCode.data.options.dimensionType,
                                                        ],                                                          level || ''); 
        case Code.base.mediaType.GOOGLE.code        : return Code.get(GoogleCode.data.options.ExplorerLevel,        level || ''); 
        case Code.base.mediaType.META.code          : return Code.get(MetaCode.data.options.ExplorerLevel,          level || ''); 
    }
    return undefined;
}
